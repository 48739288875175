
import { mapGetters } from 'vuex'
import i18n from '@/vueI18n'
export default {
computed:{
    ...mapGetters({
        record: 'branch/view/record'
    }),
    isRTL(){
        return i18n.locale == 'ar'
    },
    namePresenter(){
        return this.isRTL ? this.record.name['ar'] : this.record.name['en'] 
    }
}
}
