
import i18n from '@/vueI18n';
import { mapGetters, mapActions } from 'vuex';
// import  videoPlayer  from 'vue-videojs7'
import FsLightbox from 'fslightbox-vue';
import BranchImageCaption from '@/modules/branch/components/branch-image-caption.vue';
// import Gallery from "vue-cover-gallery"
// import Lightbox from "@/lightbox.vue";

export default {
  name: 'app-branch-view-page',
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      record: 'branch/view/record',
      loading: 'branch/view/loading',
    }),
    isRTL() {
      return i18n.locale == 'ar';
    },
    evenNumbers() {
      if (this.record) {
        return [...Object.values(this.record.images)]
          .map((_, i) => i)
          .filter((i) => i % 3 === 0);
      }
      return [];
    },

    isDark() {
      return this.$q.dark.isActive;
    },
    options() {
      return {
        rewind: true,
        // width: 800,
        // arrowPath: '/images/icons/arrow-right.svg',
        // arrows: false,
        // padding: this.is_screen_xs ? '0': '5rem',
        gap: '1.25rem',
        perPage: 2,
        // direction: 'rtl',
        breakpoints: {
          640: {
            // destroy: true,
            perPage: 1,
            gap: '1rem',
          },
          480: {
            perPage: 1,
            gap: '1rem',
          },
        },
      };
    },
  },

  data() {
    return {
      slide: 0,
      toggler: false,
      BranchImageCaption: BranchImageCaption,
      modalImages: [],
      captionArray: [],
      slideIndex: 0,
    };
  },
  components: {
    FsLightbox,
  },
  methods: {
    openImageModal(index) {
      this.toggler = !this.toggler;
      this.slideIndex = index;
    },
    ...mapActions({
      doFind: 'branch/view/doFind',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    getFirstWordPresenter(description) {
      if (this.isRTL) {
        return this.getFirstWord(description['ar']);
      } else {
        return this.getFirstWord(description['en']);
      }
    },
    getFirstWord(str) {
      let spaceIndex = str.indexOf(' ');
      return spaceIndex === -1
        ? str
        : str.substr(0, spaceIndex);
    },
    getSecondWordPresenter(description) {
      if (this.isRTL) {
        return this.getSecondWord(description['ar']);
      } else {
        return this.getSecondWord(description['en']);
      }
    },
    getSecondWord(str) {
      // this.removeFirstWord(str)
      return str.split(/\s+/).slice(1, 2).toString();
    },

    removeFirstTwoWord(description) {
      // debugger
      var str = this.isRTL
        ? description['ar']
        : description['en'];
      str = str.split(/\s+/).slice(2, str.length);
      return str.join(' ');
    },

    namePresenter(value) {
      return this.isRTL ? value['ar'] : value['en'];
    },
  },
  async created() {
    await this.doFind(this.$route.params.id);
    const imagesNumber = Object.values(
      this.record.images,
    ).length;
    this.captionArray = new Array(imagesNumber);
    this.captionArray.fill(this.BranchImageCaption);
  },
};
